$primary: #203296;

.nav-item {
    color: $primary;
    .active {
        color: white !important;
        background-color: $primary !important;
        
    }
    :hover {
        
    }
}

h2, h4 {
    color: $primary !important;
}
.card-header {
    background-color: white !important;
    color: $primary !important;
    border-bottom: none !important;
}
.btn-editar  {
    color: $primary !important;
    text-align: center;
 }
footer {
    p {
        font-size: smaller;
        padding: 10px;
    }
}


@import "../node_modules/bootstrap/scss/bootstrap";


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
